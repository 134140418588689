<template>
    <div>
        <div class="signBack">
            <div v-if="popTitle=='遇到问题'" class="signBack-radio">
                <el-radio-group v-model="ruleForm.state">
                    <el-radio style="margin-bottom: 20px;" :label="1">管理员认证有误</el-radio>
                    <el-radio style="margin-bottom: 20px;" :label="2">企业信息有误</el-radio>
                    <el-radio style="margin-bottom: 20px;" :label="3">银行账号有误</el-radio>
                    <el-radio style="margin-bottom: 20px;" :label="4">其他认证问题</el-radio>
                </el-radio-group>
            </div>
            <!-- <div v-if="popTitle=='遇到问题'" class="signBack-radio">
                <el-radio-group v-model="ruleForm.state">
                    <el-radio style="margin-bottom: 20px;" :label="1">法人验证码收不到</el-radio>
                    <el-radio style="margin-bottom: 20px;" :label="2">合同信息有问题</el-radio>
                    <el-radio style="margin-bottom: 20px;" :label="3">其他问题</el-radio>
                </el-radio-group>
            </div> -->
            <el-form :label-position="right" label-width="80px" :model="ruleForm" :rules="rules" ref="ruleForm1">
                <el-form-item label="姓名" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="phone">
                  <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="desc">
                  <el-input type="textarea" v-model="ruleForm.desc"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div style="border-top: 1px solid #E6E6E6;padding-top: 30px;">
            <div style="width:200px;margin:0 auto;" class="flex flex-justify-between">
                <el-button @click="hide()" size="small" type="primary" plain>取消</el-button>
                <el-button @click="submit()" size="small" type="primary">提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['popTitle'],
        data() {
            return {
                right:'right',
                ruleForm:{
                    name:null,
                    phone:null,
                    desc:null,
                    state:1,
                },
                rules: {
                    name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
                    phone: [{ required: true, message: '请填写联系方式', trigger: 'blur' }],
                    desc: [{ required: true, message: '请填写详细描述', trigger: 'blur' }],
                },
            }
        },
        watch: {},
        computed: {},
        created () {
            
        },
        methods: {
            submit() {
                this.$refs['ruleForm1'].validate((valid) => {
                    if (valid) {
                        this.$emit('hide');
                        this.$message.success("提交成功");
                    }
                });
            },
            hide() {
                this.$emit('hide');
            },
        }
    }
</script>
<style scoped>
    .signBack {
        width: 400px;
        margin: 0 auto;
        padding-bottom: 10px;
    }
    .signBack-radio {
        width: 320px;
        margin: 0 auto;
        padding: 10px 0 10px 0;
    }
</style>
<template>
    <div class="sign">
        <!-- <div class="sign-icon">
            <i style="margin-right:5px;" class="iconfont icon-dggfg"></i>
            <span v-if="popTitle=='企业认证'">已提交</span>
            <span v-if="popTitle=='签约'">已签订</span>
        </div> -->
        <div class="sign-title">
            <div v-if="popTitle=='企业认证'">企业信息提交完成后，请刷新查看状态</div>
            <div v-if="popTitle=='个人认证'">个人信息提交完成后，请刷新查看状态</div>
            <div v-if="popTitle=='签约'">合同签订完成后，请刷新查看状态</div>
            <div v-if="popTitle=='签章'">签章完成后，请刷新查看状态</div>
        </div>
        <div>
            <div class="sign-button" @click="refresh()">查看最新状态</div>
            <div class="sign-text">
                <div v-if="popTitle=='企业认证'">认证遇到问题？请致电客服：18979787390</div>
                <div v-if="popTitle=='个人认证'">认证遇到问题？请致电客服：18979787390</div>
                <div v-if="popTitle=='签约'">合同签订遇到问题？请致电客服：18979787390</div>
                <div v-if="popTitle=='签章'">签章遇到问题？请致电客服：18979787390</div>
            </div>
            <!-- <div @click="submit()">未提交成功，签约时遇到问题</div> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'sign',
        props: ['popTitle'],
        data() {
            return {
                
            }
        },
        watch: {},
        computed: {},
        created () {
            console.log(this.popTitle);
        },
        methods: {
            refresh() {
                this.$emit('data');
                this.$emit('hide');
            },
            submit() {
                this.$emit('signState','遇到问题');
            },
        }
    }
</script>
<style scoped>
    .sign {
        text-align: center;
    }
    .sign .sign-icon {
        font-size: 14px;
        color: #2BBAAE;
    }
    .sign-title {
        padding: 25px;
        font-size: 14px;
        font-weight: 500;
        color: #808080;
        line-height: 20px;
        border-bottom: 1px solid #E6E6E6;
    }
    .sign-button {
        width: 110px;
        height: 30px;
        line-height: 30px;
        margin: 0 auto;
        line-height: 30px;
        background: #5074EE;
        font-size: 14px;
        /* color: #FAFAFA; */
        color: #fff;
        cursor: pointer;
        -webkit-user-select:none;
        -moz-user-select:none;
        margin-top: 30px;
    }
    .sign-text {
        font-size:12px;color:#B3B3B3;
        padding: 20px 0;
    }
</style>